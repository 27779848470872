/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux';

import appReducer from './redux/reducers/app.reducer';
import reducer from './redux/reducers/alarm.reducer';
import scenarioReducer from './redux/reducers/scenario.reducer';
import teamReducer from './redux/reducers/team.reducer';
import deviceReducer from './redux/reducers/device.reducer';
import geofenceReducer from './redux/reducers/geofence.reducer';
import quickTextReducer from './redux/reducers/quickText.reducer';
import beaconReducer from './redux/reducers/beacon.reducer';
import siteReducer from './redux/reducers/site.reducer';
import contactReducer from './redux/reducers/contact.reducer';
import protocolReducer from './redux/reducers/protocol.reducer';
import eventReducer from './redux/reducers/event.reducer';
import assistanceRequestReducer from './redux/reducers/assistanceRequest.reducer';
import dashboardReducer from './redux/reducers/dashboard.reducer';
import userReducer from './redux/reducers/user.reducer';

import { APP } from './redux/constants/app.constants';

import { DEVICES } from './redux/constants/device.constants';
import { TEAMS } from './redux/constants/team.constants';
import { ALARMS } from './redux/constants/alarm.constants';
import { GEOFENCES } from './redux/constants/geofence.constants';
import { QUICK_TEXTS } from './redux/constants/quickText.constants';
import { SCENARIOS } from './redux/constants/scenario.constants';
import { BEACONS } from './redux/constants/beacon.constants';
import { PROTOCOLS } from './redux/constants/protocol.constants';
import { CONTACTS } from './redux/constants/contact.constants';
import { SITES } from './redux/constants/site.constants';
import { EVENTS } from './redux/constants/event.constants';
import { ASS_REQUESTS } from './redux/constants/assistanceRequest.constants';
import { DASHBOARD } from './redux/constants/dashboard.constants';
import {USERS} from "./redux/constants/user.constants";

export default function createReducer(injectedReducers) {

	const customReducers = {
		[APP]: appReducer,
		[DASHBOARD]: dashboardReducer,
		[DEVICES]: deviceReducer,
		[TEAMS]: teamReducer,
		[ALARMS]: reducer,
		[SCENARIOS]: scenarioReducer,
		[GEOFENCES]: geofenceReducer,
		[BEACONS]: beaconReducer,
		[SITES]: siteReducer,
		[QUICK_TEXTS]: quickTextReducer,
		[CONTACTS]: contactReducer,
		[PROTOCOLS]: protocolReducer,
		[EVENTS]: eventReducer,
		[ASS_REQUESTS]: assistanceRequestReducer,
		[USERS]: userReducer,
	};
	return combineReducers({
		...customReducers,
		...injectedReducers
	});
}
