import User from '../models/User';
import jwtDecode from 'jwt-decode';
import { getStore } from '../../configureStore';
import * as Sentry from '@sentry/browser';
import i18next from 'i18next';
import * as env from '../../env';
import { getWhitelabel, isLive } from '../../env';
import dayjs from 'dayjs';
import { checkJWT, logout, newToken } from '../../redux/actions/app.actions';
import {consoleError, consoleLog, consoleWarn} from "../log";

export default class BaseAPI {

	static version = null;
	static inMemoryToken = null;

	static get token() {
		try {
			if(BaseAPI.inMemoryToken) return BaseAPI.inMemoryToken;
			const user = localStorage.getItem('user');
			return JSON.parse(user)?.token;
		} catch (e) {
			return null;
		}
	}
	static get refreshToken() {
		try {
			return localStorage.getItem('rt');
		} catch (e) {
			return null;
		}
	}
	static get adminUser() {
		try {
			const admin = localStorage.getItem('adminUser');
			if(!admin) return null;
			return JSON.parse(admin).id;
		} catch (e) {
			return null;
		}
	}

	async login(username: string, password: string, twoFACookie: string, twoFaMethod): Promise<User> {
		const res = await this.request('/pub/login', 'POST', { username, password, twoFACookie, twoFA: true, twoFaMethod });
		if(res.twoFA) return res;
		const decoded = jwtDecode(res);
		decoded.user.token = res;
		return new User(decoded.user);
	}
	async loginTwoFA(username: string, password: string, code: string, method): Promise<User> {
		const jwt = await this.request('/pub/login/2fa', 'POST', { username, password, code, method });
		const decoded = jwtDecode(jwt);
		decoded.user.token = jwt;
		return new User(decoded.user);
	}
	async getTwoFACookie(email, password) {
		return await this.request('/pub/login/2fa/cookie', 'POST', { email, password });
	}

	async loginARCWithToken(token: string): Promise<User> {
		try {
			const jwt = await this.request('/pub/login/token', 'POST', {token});
			const decoded = jwtDecode(jwt);
			decoded.user.token = jwt;
			return new User(decoded.user);
		} catch (e) {
			if((await e.text()) === 'closed') {
				return window.location.href = '/?msg=alarmIsAlreadyClosed&type=success';
			}
			return window.location.href = '/?msg=linkInvalid&type=warning';
		}
	}

	async loginPublicAlarmWithToken(token) {
		try {
			const jwt = await this.request('/pub/login/token', 'POST', {token, public: true});
			const decoded = jwtDecode(jwt);
			decoded.user.token = jwt;
			return new User(decoded.user);
		} catch (e) {
			if((await e.text()) === 'closed') {
				return window.location.href = '/?msg=alarmIsAlreadyClosed&type=success';
			}
			return window.location.href = '/?msg=linkInvalid&type=warning';
		}
	}

	async loginPublicLiveLocationWithToken(token) {
		try {
			const jwt = await this.request('/pub/login/live-location', 'POST', {token});
			const decoded = jwtDecode(jwt);
			decoded.user.token = jwt;
			return new User(decoded.user);
		} catch (e) {
			const msg = await e.text();
			window.location.href = `/?msg=${msg}&type=warning`;
		}
	}
	
	async loginPresentation(pageType, accountId, token: string) {
		try {
			const res = await this.request(`/pub/presentation/login`, 'POST', { pageType, accountId, token });
			if(Array.isArray(res)) {
				const [jwt, options] = res;
				const decoded = jwtDecode(jwt);
				decoded.user.token = jwt;
				return [new User(decoded.user), options];
			} else {
				//wrong whitelabel
				window.location.href = res + window.location.pathname;
			}
		} catch (e) {
			setTimeout(() => window.location.reload(), 5000);
		}
	}

	async resetUser(email: string, recaptcha: string) {
		if(recaptcha) {
			return await this.request('/pub/userAccount/password/reset', 'POST', {email, recaptcha});
		}
		return await this.request('/sec/userAccount/password/reset', 'POST', { email });
	}

	async setPassword(newPassword: string) {
		const token = await this.request('/sec/login/changePassword', 'POST', { newPassword });
		const decoded = jwtDecode(token);
		decoded.user.token = token;
		return new User(decoded.user);
	}

	async changePassword(currentPassword: string, newPassword: string) {
		const token = await this.requestPlain('/sec/userAccount', 'PUT', { currentPassword, newPassword });
		if(!token) return false;
		const decoded = jwtDecode(token);
		decoded.user.token = token;
		return new User(decoded.user);
	}

	async toggleNotifyEmail() {
		return this.request('/sec/user/notifyEmail', 'PUT');
	}
	async getNotifyEmail() {
		return this.request('/sec/user/notifyEmail', 'GET');
	}

	async getFAQs() {
		return this.request('/sec/faq');
	}

	missingTranslationKey(key, language) {
		try {
			return this.request('/pub/lang/missingKey', 'POST', { key, language }).then();
		} catch(e) {
			//Ignore
		}
	}

	async sendContactMessage(message) {
		return this.request('/sec/user/contact', 'POST', {message});
	}

	async toggleNotification(notification, enabled) {
		return this.request('/sec/user/notifications', 'PUT', {
			notification,
			enabled
		});
	}

	async keepAlive() {
		return await this.request('/sec/user/keepAlive', 'GET');
	}

	async getNotifications() {
		return await this.request('/sec/user/notifications', 'GET');
	}

	async requestData() {
		await this.request('/sec/data/ws', 'GET');
	}

	async uploadFile(path: string, method: string, file: any) {
		const body = new FormData();
		body.append('file', file);
		return new Promise(async (resolve) => {
			const res = await fetch(`${env.apiBase()}${path}`, { method, body, headers: {
					Authorization: `Bearer ${BaseAPI.token}`
				}});
			const json = await res.text();
			resolve(JSON.parse(json));
		});
	}

	async requestPlain(path: string, method: string, body: any, binary = false, headers: Object = {}) {
		return this.request(path, method, body, headers, false, binary);
	}

	request(path, method, body, headers = {}, json = true, binary = false, attempt = 1) {
		return new Promise(async (resolve, reject) => {
			let response = null;
			try {
				headers.OriginApp = 'vevigo/web';
				headers['x-whitelabel'] = getWhitelabel().key;
				if (!headers['Content-Type']) {
					headers['Content-Type'] = 'application/json; charset=utf-8';
				}
				if (!headers.Language) {
					headers.Language = i18next.language;
				}
				if (BaseAPI.token && !headers.Authorization) {
					let token = BaseAPI.token;
					const {exp} = jwtDecode(token);
					if(exp < dayjs().unix() && BaseAPI.refreshToken) {
						token = await this.refreshJWT();
					}
					headers.Authorization = `Bearer ${token}`;
				}
				headers['x-platform-remember'] = localStorage.getItem('rememberMe');
				if(BaseAPI.adminUser) {
					headers.admin = BaseAPI.adminUser;
				}
				const opts = {
					method,
					headers
				};
				if (body) {
					opts.body = JSON.stringify(body);
				}
				try {
					let url = `${env.apiBase()}${path}`;
					const res = await fetch(url, opts);
					if(res.status >= 502 && attempt < 3) {
						setTimeout(() => {
							this.request(path, method, body, headers, json, binary, attempt + 1).then(resolve, reject);
						}, 5000 * attempt);
						return;
					}
					if(!res.ok) resolve = reject;
					if (res.status === 401) {
						await getStore().dispatch(checkJWT());
						return resolve(res);
					}
					this.handleHeaders(res);
					if(binary) {
						response = await res.arrayBuffer();
					} else {
						response = await res.text();
					}
					if((response && json)) {
						try {
							resolve(JSON.parse(response));
						} catch (e) {
							consoleError({ path, method, body, response, e });
							Sentry.addBreadcrumb({
								category: 'api',
								message: e.message,
								data: { path, method, body, response },
								level: Sentry.Severity.Error
							});
							Sentry.captureException(e);
							debugger;
						}
					} else {
						resolve(response);
					}
				} catch (e) {
					consoleWarn({ path, method, body, response, e });
					reject(e);
				}
			} catch (error) {
				consoleLog({error, response});
				reject(error);
			}
		});
	}

	handleHeaders(res) {
		if(res.headers.get('version')) {
			const version = res.headers.get('version');
			if(BaseAPI.version !== version) {
				BaseAPI.version = version;
			}
		}
		if(res.headers.get('rt')) {
			localStorage.setItem('rt', res.headers.get('rt'));
		}
	}

	async refreshJWT() {
		const body = {
			jwt: BaseAPI.token,
			rt: BaseAPI.refreshToken
		};
		let res = { status: 401 };
		try {
			res = await fetch(`${env.apiBase()}/pub/user/keepAlive`, {
				method: 'POST',
				body: JSON.stringify(body)
			});
		} catch (_) {
			// Session no longer valid
		}
		if(res.status === 200) {
			const text = await res.text();
			getStore().dispatch(newToken(text));
			return text;
		} else if(res.status === 401 && BaseAPI.refreshToken) {
			getStore().dispatch(logout(true));
		}
		return BaseAPI.token;
	}
}

if(!isLive()) {
	global.api = new BaseAPI();
}