import {all, delay, put, select, takeEvery, takeLatest, takeLeading} from 'redux-saga/effects';
import * as Sentry from '@sentry/browser';

import {
    selectCurrentUser,
    selectEnqueueSnackbar,
    selectIsConnected,
    selectWsInit,
} from '../selectors/app.selectors';
import {
    APP_LOGIN_ADMIN,
    APP_LOGOUT,
    APP_MOUNTED,
    CHECK_JWT,
    CONNECTION_CHANGED,
    ENQUEUE_SNACKBAR,
    FORCE_RELOAD,
    GET_DATA,
    KEEP_ALIVE,
    LANGUAGE_CHANGED,
    NEW_ADMIN_TOKEN,
    NEW_TOKEN,
    RECONNECT_WEBSOCKET,
    RETRY_GET_DATA, SET_IS_ONLINE,
    USER_CHANGED,
} from '../constants/app.constants';
import {MyWebSocket} from '../../utils/MyWebSocket';
import BaseAPI from '../../utils/API/BaseAPI';
import {deviceSagas} from './device.saga';
import {alarmSagas} from './alarm.saga';
import {dashboardSagas} from './dashboard.saga';
import {teamSagas} from './team.saga';
import {scenarioSagas} from './scenario.saga';
import {accountSagas} from './account.saga';
import {geofenceSagas} from './geofence.saga';
import {beaconSagas} from './beacon.saga';
import {siteSagas} from './site.saga';
import {eventSagas} from './event.saga';
import {assistanceRequestSagas} from './assistanceRequest.saga';
import User from '../../utils/models/User';
import {AdminAPI} from '../../utils/API/AdminAPI';
import {
    logout as doLogout,
    newToken,
    retryGetData as retryGetDataAction, setAppEnabled, toggleDisconnectedSound,
    userChanged,
} from '../actions/app.actions';
import i18next from 'i18next';
import {UserAPI} from '../../utils/API/UserAPI';
import dayjs from 'dayjs';
import {
    WS_DATA_ALARM,
    WS_DATA_ALARMS, WS_DATA_ASS_REQUEST,
    WS_DATA_ASS_REQUESTS,
    WS_FORCE_RELOAD,
    WS_REQUEST_RELOAD_DATA
} from '../constants/alarm.constants';
import {showAlert} from '../../containers/AlertDialog/actions';
import {getI18n} from 'react-i18next';
import {getActivePage, getLangFromLocale} from '../../utils/functions';
import jwtDecode from 'jwt-decode';
import {getStore} from '../../configureStore';
import {getWhitelabel, isLive} from '../../env';
import {AUTH_SUCCESS} from '../../containers/LoginPage/constants';
import {getNavigator} from '../../Navigator';
import {selectOpenAlarms} from "../selectors/alarm.selector";
import {selectOpenAssistanceRequests} from "../selectors/assistanceRequest.selector";
import {selectOptions} from "../../containers/PresentationPage/selectors";
import {userSagas} from "./user.saga";
import {authSuccess} from "../../containers/LoginPage/actions";
import {subscribeToWsTopics} from "../actions/dashboard.actions";
import {consoleDebug, consoleWarn} from "../../utils/log";

global.toPage = page => {
    if (isLive()) return;
    getNavigator()(page);
};
global.myDispatch = action => {
    if (isLive()) return;
    getStore().dispatch(action);
};

const api = new BaseAPI();
const adminApi = new AdminAPI();
const userApi = new UserAPI();

function* saveUser({store}) {
    const user = yield select(selectCurrentUser());
    if (store !== false && !BaseAPI.inMemoryToken) {
        localStorage.setItem('user', JSON.stringify(user.toJS()));
    } else {
        BaseAPI.inMemoryToken = user.token;
    }
}

function* loadWebSocket(props) {
    const force = !!props?.force;
    const delayMs = props?.delayMs || 0;
    const websocket = MyWebSocket.inst();
    const isOpen = yield websocket?.isOpen();
    if (isOpen && !force) return;
    yield delay(delayMs || 0);
    const user = yield select(selectCurrentUser());
    if (user) yield loginWebSocket();
}

function* loginWebSocket() {
    yield MyWebSocket.inst().restart();
    const user = yield select(selectCurrentUser());
    MyWebSocket.inst().subscribe('requestReloadData');
    const page = yield select(selectOptions());
    if(user.isPresentation) {
        yield put(subscribeToWsTopics());
       return; 
    }
    if(!page) {
        MyWebSocket.inst().subscribe('*');
    }
}

let dataRetryTimeout = null;

function* connectionChanged({isConnected}) {
    if (dataRetryTimeout) clearTimeout(dataRetryTimeout);
    const user: User = yield select(selectCurrentUser());
    if (isConnected && user) {
        yield delay(300);
        yield api.requestData();
        dataRetryTimeout = setTimeout(() => {
            getStore().dispatch(retryGetDataAction());
        }, 3000);
    }
}

function* retryGetData() {
    const websocketInitialized = yield select(selectWsInit());
    if (websocketInitialized) return;
    yield getData();
}

function* getData() {
    const user: User = yield select(selectCurrentUser());
    if (user.isUser || user.isPresentation) {
        yield api.requestData();
    }
}

function* redirectUser() {
    const user: User = yield select(selectCurrentUser());
    if (user && !user.lastLogin && !user.isPresentation) {
        getNavigator()('/newPassword');
        return;
    }
    const page = getActivePage();
    if (page.indexOf('admin') >= 0 && !user.isSuperAdmin) {
        return getNavigator()('/');
    }
}

function* logout({isExpired}) {
    yield Promise.resolve();
    if (MyWebSocket.inst()) {
        MyWebSocket.inst().close();
    }
    const wasAdmin = !!localStorage.getItem('adminUser');
    localStorage.removeItem('user');
    localStorage.removeItem('adminUser');
    localStorage.removeItem('agreedToTermsOfUse');
    localStorage.removeItem('rt');
    if (isExpired) {
        const user = yield select(selectCurrentUser());
        const wasPresentation = user.isPresentation;
        //because of potential javascript errors, reload the page
        if (wasAdmin) {
            window.location.href = '/?msg=adminSessionExpiredMessage&type=warning';
        } else if (wasPresentation) {
            window.location.reload();
        } else {
            window.location.href = '/?msg=sessionExpiredMessage&type=warning';
        }
    } else {
        window.location.href = '/';
    }
}

function* loginAdmin() {
    const user = yield select(selectCurrentUser());
    localStorage.removeItem('agreedToTermsOfUse');
    yield adminApi.loginAdmin();
    if(user?.isUser) {
        window.location.href = `/admin/devices/${user.accountId}`;
    } else {
        window.location.href = `/`;
    }
}
function* setLanguage() {
    const user = yield select(selectCurrentUser());
    const newLang = user.get('language');
    if (i18next.language !== newLang) {
        yield i18next.changeLanguage(getLangFromLocale(newLang));
    }
}

function* saveLanguage({language, callback}) {
    const lang = getLangFromLocale(language);
    dayjs.locale(lang);
    const user = yield select(selectCurrentUser());
    if (user && !user.viaAdmin) {
        yield userApi.saveLanguage(language);
    }
    if(callback) callback();
    if (i18next.language !== language) {
        yield i18next.changeLanguage(lang);
    }
}

function* requestForceReload({doLogout}) {
    yield adminApi.forceReload(doLogout);
}

function* forceReload({data}) {
    const i18n = getI18n();
    let text = i18n.t('forceReloadWarning.1');
    if (+data === 1) {
        text += `\n${i18n.t('forceReloadWarning.2')}`;
    }
    yield put(showAlert(
        text,
        i18n.t('reload'), {
            disableBackdropClick: true,
            confirmText: i18n.t('reload'),
            onConfirm: () => {
                if (+data === 1) {
                    put(doLogout());
                }
                window.location.reload();
            },
        },
    ));
}

function* doEnqueueSnackbar({input, attrs}) {
    const enqueueSnackbar = yield select(selectEnqueueSnackbar());
    enqueueSnackbar(input, attrs);
}

function* checkForAlert() {
    const urlParams = new URLSearchParams(window.location.search);
    const alert = urlParams.get('alert');
    if (alert) {
        getNavigator()(window.location.pathname);
        const i18n = getI18n();
        yield put(showAlert(i18n.t(alert), i18n.t('alert')));
    }
}

function* checkJWT() {
    const user = yield select(selectCurrentUser());
    if (!user) return;
    const token = BaseAPI.token;
    try {
        let {exp} = jwtDecode(token);
        if (exp < dayjs().unix()) {
            if (localStorage.getItem('rememberMe') === 'yes') {
                yield put(setAppEnabled(false));
                yield api.refreshJWT();
            } else {
                yield put(doLogout(true));
                return;
            }
        }
    } catch (_) {
    } finally {
        yield put(setAppEnabled(true));
    }
    const isConnected = yield select(selectIsConnected());
    if (!isConnected) {
        yield loginWebSocket();
    }
}

function* keepAlive() {
    const user = yield select(selectCurrentUser());
    if (!user) return;
    try {
        const {token, adminToken} = yield api.keepAlive();
        if (!token) return;
        yield put(newToken(token));
        if (adminToken) {
            yield saveNewAdminToken({token: adminToken});
        }
    } catch (_) {
        debugger;
    }
}

function* saveNewToken({token}) {
    const decoded = jwtDecode(token);
    consoleDebug('new token: ' + token);
    decoded.user.token = token;
    const user = new User(decoded.user);
    localStorage.setItem('user', JSON.stringify(user.toJS()));
    yield put(userChanged(user));
    MyWebSocket.inst().token = token;
}

function* saveNewAdminToken({token}) {
    yield Promise.resolve();
    const decoded = jwtDecode(token);
    consoleDebug('new admin token: ' + token);
    decoded.user.token = token;
    const user = new User(decoded.user);
    localStorage.setItem('adminUser', JSON.stringify(user.toJS()));
}

function* reconnectWsAfterMount() {
    yield delay(1000);
    yield loadWebSocket();
}

function *identifySentry() {
    try {
        const user = yield select(selectCurrentUser());
        if (!user) return;
        let username = user.email;
        const shadowUser = localStorage.getItem('adminUser');
        if (shadowUser) {
            const type = user.isShadowAccountManager ? 'Account manager' : 'SuperAdmin'
            const userObj = JSON.parse(shadowUser);
            username = `${user.accountName} via ${userObj.email} (${type})`;
        }
        Sentry.setUser({username})
    } catch (e) {
        consoleWarn('could not login sentry')
        consoleWarn(e);
    }
}

function *refreshDocumentTitle() {
    const user = yield select(selectCurrentUser());
    if(!user) return;
    const i18n = getI18n();
    const isConnected = select(selectIsConnected());
    if(!isConnected || !navigator.onLine) {
        return document.title = `!! ${i18n.t('connectionLost')} !!`;
    }
    if(!user.isPresentation) {
        const openAlarms = yield select(selectOpenAlarms());
        if (!!openAlarms.size) {
            return document.title = `!! ${i18n.t('openAlarms')} !!`;
        }
        const openAssistanceRequests = yield select(selectOpenAssistanceRequests());
        if (!!openAssistanceRequests.size) {
            return document.title = `!! ${i18n.t('pendingAssistanceRequests')} !!`;
        }
    }
    const whitelabel = getWhitelabel();
    document.title = whitelabel.platformName || 'Web app';
}

function *setSoundSettings() {
    const user = yield select(selectCurrentUser());
    if(user?.isARC !== true) return;
    yield put(toggleDisconnectedSound(true));
}

function *downloadFile() {
    const hash = window.location.hash;
    if(hash?.indexOf('#dwnld=') !== 0) return;
    window.location.hash = '#';
    const url = hash.replace('#dwnld=', '');
    window.open(url);
    const t = getI18n().t;
    const body = t('yourFileIsDownloading');
    yield put(showAlert(body));
}

function* tryLoginPublicViaToken() {
    const currentUser = yield select(selectCurrentUser());
    if(currentUser) return;

    if(!window.location.pathname.match(/\/live-location\/.*/)) return;
    const token = window.location.pathname.split('/')[2];
    const user: User = yield api.loginPublicLiveLocationWithToken(token);
    yield put(authSuccess(user, false));
}

function* appSaga() {
    yield takeEvery(GET_DATA, getData);
    yield takeEvery(WS_REQUEST_RELOAD_DATA, getData);
    yield takeEvery(RETRY_GET_DATA, retryGetData);
    yield takeEvery(WS_FORCE_RELOAD, forceReload);
    yield takeEvery(FORCE_RELOAD, requestForceReload);
    yield takeEvery(USER_CHANGED, saveUser);
    yield takeEvery(AUTH_SUCCESS, saveUser);
    yield takeEvery(AUTH_SUCCESS, redirectUser);
    yield takeEvery(AUTH_SUCCESS, loginWebSocket);
    yield takeEvery(AUTH_SUCCESS, setLanguage);
    yield takeEvery(AUTH_SUCCESS, setSoundSettings);
    yield takeEvery(APP_MOUNTED, setSoundSettings);
    yield takeLatest(LANGUAGE_CHANGED, saveLanguage);
    yield takeEvery(APP_MOUNTED, redirectUser);
    yield takeEvery(APP_MOUNTED, loadWebSocket);
    yield takeEvery(APP_MOUNTED, reconnectWsAfterMount);
    yield takeEvery(APP_MOUNTED, checkForAlert);
    yield takeEvery(NEW_TOKEN, saveNewToken);
    yield takeEvery(NEW_ADMIN_TOKEN, saveNewAdminToken);
    yield takeEvery(CHECK_JWT, checkJWT);
    yield takeEvery(APP_LOGOUT, logout);
    yield takeLatest(APP_LOGIN_ADMIN, loginAdmin);
    yield takeEvery(CONNECTION_CHANGED, connectionChanged);
    yield takeEvery(ENQUEUE_SNACKBAR, doEnqueueSnackbar);
    yield takeEvery(KEEP_ALIVE, keepAlive);
    yield takeLeading(RECONNECT_WEBSOCKET, loadWebSocket);
    yield takeEvery(APP_MOUNTED, identifySentry);
    yield takeEvery(AUTH_SUCCESS, identifySentry);
    
    yield takeEvery(WS_DATA_ALARMS, refreshDocumentTitle);
    yield takeEvery(WS_DATA_ALARM, refreshDocumentTitle);
    yield takeEvery(WS_DATA_ASS_REQUESTS, refreshDocumentTitle);
    yield takeEvery(WS_DATA_ASS_REQUEST, refreshDocumentTitle);
    yield takeEvery(CONNECTION_CHANGED, refreshDocumentTitle);
    yield takeEvery(SET_IS_ONLINE, refreshDocumentTitle);
    yield takeEvery(APP_MOUNTED, downloadFile);
    yield takeLatest(APP_MOUNTED, tryLoginPublicViaToken);
    
    yield all(accountSagas);
    yield all(deviceSagas);
    yield all(alarmSagas);
    yield all(dashboardSagas);
    yield all(teamSagas);
    yield all(scenarioSagas);
    yield all(geofenceSagas);
    yield all(beaconSagas);
    yield all(siteSagas);
    yield all(eventSagas);
    yield all(assistanceRequestSagas);
    yield all(userSagas);
}

export default appSaga;