export const deviceTypesInv = {
    TwigProtector: 4,
    TwigProtectorPro: 5,
    TwigProtectorEX: 6,
    TwigProtectorProEX: 9,
    TwigEmbody: 7,
    TwigSOSCard: 10,
    TwigProtectorProOld: 11,
    TeltonikaTMT250Old: 12,
    TeltonikaGH5200: 13,
    Generic: 14,
    App: 15,
    TeltonikaTMT250: 16,
    TwigOne: 17,
    WebUser: 18,
    MoxaE12010: 19,
    TwigNeo: 20,
    TwigOneEx: 21,
    EmeritEWG100: 22,
    ESPA: 23,
    TwigRemoteButton: 24,
    TwigSOSCardP5: 25,
    EviewEV04: 26,
    QueclinkGL320M: 27,
    TeltonikaFMC640: 28,
    TwigEasy5: 29,
    ESPA2: 30,
    TeltonikaFMC650: 31,
    // EmeritConnect: 32,
    // EmeritERG170: 33,
    // EmeritERG530: 34,
    // EmeritERG650: 35,
    // EmeritERG655: 36,
    // EmeritEIS330: 37,
    // EmeritEIS530: 38,
    // EmeritEIS655: 39,
    TwigEasy4: 40,
    TwigEon: 41
};

export const deviceTypes = {
    0: () => '',
    [deviceTypesInv.TwigProtector]: () => 'Twig Protector',
    [deviceTypesInv.TwigProtectorPro]: () => 'Twig Protector Pro',
    [deviceTypesInv.TwigProtectorEX]: () => 'Twig Protector EX',
    [deviceTypesInv.TwigProtectorProEX]: () => 'Twig Protector Pro EX',
    [deviceTypesInv.TwigEmbody]: () => 'Twig Embody',
    [deviceTypesInv.TwigSOSCard]: () => 'Twig SOS card P4',
    [deviceTypesInv.TwigProtectorProOld]: () => 'Twig Protector (TUP90EU)',
    [deviceTypesInv.TeltonikaTMT250Old]: () => 'Teltonika TMT250',
    [deviceTypesInv.TeltonikaGH5200]: () => 'Teltonika GH5200',
    [deviceTypesInv.Generic]: (t) => t ? t('deviceType.generic') : 'Generic',
    [deviceTypesInv.App]: (t) => t ? t('deviceType.app') : 'App',
    [deviceTypesInv.TeltonikaTMT250]: () => 'Teltonika TMT250',
    [deviceTypesInv.TwigOne]: () => 'Twig One',
    [deviceTypesInv.WebUser]: () => '',
    [deviceTypesInv.MoxaE12010]: () => 'I/O',
    [deviceTypesInv.TwigNeo]: () => 'Twig Neo',
    [deviceTypesInv.TwigOneEx]: () => 'Twig One Ex',
    [deviceTypesInv.EmeritEWG100]: () => 'Emerit e-WG100',
    [deviceTypesInv.ESPA]: () => 'ESPA 4.4.4 Gateway',
    [deviceTypesInv.ESPA2]: () => 'ESPA 4.4.4 Gateway v2',
    [deviceTypesInv.TwigRemoteButton]: () => 'Twig Remote Button',
    [deviceTypesInv.TwigSOSCardP5]: () => 'Twig SOS card P5',
    [deviceTypesInv.EviewEV04]: () => 'Eview EV-04',
    [deviceTypesInv.QueclinkGL320M]: () => 'Queclink GL320M',
    [deviceTypesInv.TeltonikaFMC640]: () => 'Teltonika FMC640',
    [deviceTypesInv.TeltonikaFMC650]: () => 'Teltonika FMC650',
    [deviceTypesInv.TwigEasy5]: () => 'Twig Easy (P5)',
    // [deviceTypesInv.EmeritConnect]: () => 'Emerit Connect App',
    // [deviceTypesInv.EmeritERG170]: () => 'Emerit e-RG170',
    // [deviceTypesInv.EmeritERG530]: () => 'Emerit e-RG530',
    // [deviceTypesInv.EmeritERG650]: () => 'Emerit e-RG650',
    // [deviceTypesInv.EmeritERG655]: () => 'Emerit e-RG655',
    // [deviceTypesInv.EmeritEIS330]: () => 'Emerit e-IS330',
    // [deviceTypesInv.EmeritEIS530]: () => 'Emerit e-IS530',
    // [deviceTypesInv.EmeritEIS655]: () => 'Emerit e-IS655',
    [deviceTypesInv.TwigEasy4]: () => 'Twig Easy (P4)',
    [deviceTypesInv.TwigEon]: () => 'Twig Eon',
};
export const realDeviceTypes = {
    0: () => '',
    [deviceTypesInv.TwigProtector]: () => 'Twig Protector',
    [deviceTypesInv.TwigProtectorPro]: () => 'Twig Protector Pro',
    [deviceTypesInv.TwigProtectorEX]: () => 'Twig Protector EX',
    [deviceTypesInv.TwigProtectorProEX]: () => 'Twig Protector Pro EX',
    [deviceTypesInv.TwigEmbody]: () => 'Twig Embody',
    [deviceTypesInv.TwigSOSCard]: () => 'Twig SOS card P4',
    [deviceTypesInv.TwigProtectorProOld]: () => 'Twig Protector (TUP90EU)',
    [deviceTypesInv.TeltonikaTMT250Old]: () => 'Teltonika TMT250 < FW55',
    [deviceTypesInv.TeltonikaGH5200]: () => 'Teltonika GH5200',
    [deviceTypesInv.Generic]: (t) => t ? t('deviceType.generic') : 'Generic',
    [deviceTypesInv.App]: (t) => t ? t('deviceType.app') : 'App',
    [deviceTypesInv.TeltonikaTMT250]: () => 'Teltonika TMT250',
    [deviceTypesInv.TwigOne]: () => 'Twig One',
    [deviceTypesInv.WebUser]: () => 'Web',
    [deviceTypesInv.MoxaE12010]: () => 'I/O',
    [deviceTypesInv.TwigNeo]: () => 'Twig Neo',
    [deviceTypesInv.TwigOneEx]: () => 'Twig One Ex',
    [deviceTypesInv.EmeritEWG100]: () => 'Emerit e-WG100',
    [deviceTypesInv.ESPA]: () => 'ESPA 4.4.4 Gateway',
    [deviceTypesInv.ESPA2]: () => 'ESPA 4.4.4 Gateway v2',
    [deviceTypesInv.TwigRemoteButton]: () => 'Twig Remote Button',
    [deviceTypesInv.TwigSOSCardP5]: () => 'Twig SOS card P5',
    [deviceTypesInv.EviewEV04]: () => 'Eview EV-04',
    [deviceTypesInv.QueclinkGL320M]: () => 'Queclink GL320M',
    [deviceTypesInv.TeltonikaFMC640]: () => 'Teltonika FMC640',
    [deviceTypesInv.TeltonikaFMC650]: () => 'Teltonika FMC650',
    [deviceTypesInv.TwigEasy5]: () => 'Twig Easy (P5)',
    // [deviceTypesInv.EmeritConnect]: () => 'Emerit Connect App',
    // [deviceTypesInv.EmeritERG170]: () => 'Emerit e-RG170',
    // [deviceTypesInv.EmeritERG530]: () => 'Emerit e-RG530',
    // [deviceTypesInv.EmeritERG650]: () => 'Emerit e-RG650',
    // [deviceTypesInv.EmeritERG655]: () => 'Emerit e-RG655',
    // [deviceTypesInv.EmeritEIS330]: () => 'Emerit e-IS330',
    // [deviceTypesInv.EmeritEIS530]: () => 'Emerit e-IS530',
    // [deviceTypesInv.EmeritEIS655]: () => 'Emerit e-IS655',
    [deviceTypesInv.TwigEasy4]: () => 'Twig Easy (P4)',
    [deviceTypesInv.TwigEon]: () => 'Twig Eon',
};
const manufacturers = {
    'elexol': {
        name: 'Elexol',
        deviceTypes: [
            deviceTypesInv.ESPA,
            deviceTypesInv.ESPA2
        ]
    },
    'twig': {
        name: 'Twig',
        deviceTypes: [
            deviceTypesInv.TwigProtector,
            deviceTypesInv.TwigProtectorPro,
            deviceTypesInv.TwigProtectorEX,
            deviceTypesInv.TwigProtectorProEX,
            deviceTypesInv.TwigEmbody,
            deviceTypesInv.TwigSOSCard,
            deviceTypesInv.TwigProtectorProOld,
            deviceTypesInv.TwigOne,
            deviceTypesInv.TwigNeo,
            deviceTypesInv.TwigOneEx,
            deviceTypesInv.TwigRemoteButton,
            deviceTypesInv.TwigSOSCardP5,
            deviceTypesInv.TwigEasy5,
            deviceTypesInv.TwigEasy4,
            deviceTypesInv.TwigEon
        ]
    },
    'teltonika': {
        name: 'Teltonika',
        deviceTypes: [
            deviceTypesInv.TeltonikaTMT250Old,
            deviceTypesInv.TeltonikaGH5200,
            deviceTypesInv.TeltonikaTMT250,
            deviceTypesInv.TeltonikaFMC640,
            deviceTypesInv.TeltonikaFMC650
        ]
    },
    'queclink': {
        name: 'Queclink',
        deviceTypes: [
            deviceTypesInv.QueclinkGL320M
        ]
    },
    'emerit': {
        name: 'Emerit',
        deviceTypes: [
            deviceTypesInv.EmeritConnect,
            deviceTypesInv.EmeritEWG100,
            deviceTypesInv.EmeritERG170,
            deviceTypesInv.EmeritERG530,
            deviceTypesInv.EmeritERG650,
            deviceTypesInv.EmeritERG655,
            deviceTypesInv.EmeritEIS330,
            deviceTypesInv.EmeritEIS530,
            deviceTypesInv.EmeritEIS655
        ]
    },
    'eview': {
        name: 'Eview GPS',
        deviceTypes: [
            deviceTypesInv.EviewEV04
        ]
    },
    'app': {
        name: 'Cuebly',
        deviceTypes: [
            deviceTypesInv.App
        ]
    }
};
export const getManufacturer = (deviceTypeId) => {
    return Object.keys(manufacturers)
        .find(m => manufacturers[m].deviceTypes.indexOf(deviceTypeId) >= 0) || 'other';
};
export const getManufacturerName = (t, deviceTypeId) => {
    return manufacturers[getManufacturer(deviceTypeId)]?.name || t('other');
};

export const adminCannotAdd = [
    deviceTypesInv.WebUser,
    deviceTypesInv.App,
    deviceTypesInv.TwigRemoteButton
];
export const deprecated = [
    deviceTypesInv.TeltonikaTMT250Old,
    deviceTypesInv.TwigProtectorProOld,
];